import { useState, useEffect } from "react"

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

interface WindowProps {
  width: number
  height: number
}
const useWindow = (): WindowProps => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return {
    width: windowDimensions.width,
    height: windowDimensions.height,
  }
}
export default useWindow
