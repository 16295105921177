import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Toolbar,
} from "@material-ui/core"
import cohort from "../assets/cohort.png"
import { useDispatch, useSelector } from "react-redux"
import { selectToken, signOut } from "../store/auth"
import useWindow from "../hooks/useWindow"
import { Menu } from "@material-ui/icons"

const useStyles = makeStyles({
  toolbar: {
    height: "64px",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px",
  },
  toolbarSection: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  menuButton: {
    marginRight: "1rem",
  },
  logo: {
    height: "100%",
  },
  menu: {
    color: "white",
  },
})

const Navbar = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const token = useSelector(selectToken())
  const { width } = useWindow()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  console.log({ width })

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        {width < 640 && (
          <>
            <IconButton component="span" onClick={() => setDrawerOpen(true)}>
              <Menu className={classes.menu} />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <List>
                <ListItem button onClick={() => history.push("/")} color="inherit">
                  <ListItemText primary="HOME" />
                </ListItem>
                <ListItem button onClick={() => history.push("/events")} color="inherit">
                  <ListItemText primary="MY EVENTS" />
                </ListItem>
                <ListItem button onClick={() => history.push("/orgs")} color="inherit">
                  <ListItemText primary="ORGANIZATIONS" />
                </ListItem>
                {!token && (
                  <ListItem button onClick={() => history.push("/signin")} color="inherit">
                    <ListItemText primary="SIGN IN" />
                  </ListItem>
                )}
                {token && (
                  <ListItem
                    button
                    onClick={() => {
                      dispatch(signOut())
                      history.push("/signin")
                    }}
                    color="inherit"
                  >
                    <ListItemText primary="SIGN OUT" />
                  </ListItem>
                )}
              </List>
            </Drawer>
          </>
        )}
        <div className={classes.toolbarSection}>
          <img className={classes.logo} src={cohort} alt="logo" />
        </div>
        {width >= 640 && (
          <div className={classes.toolbarSection}>
            <Button onClick={() => history.push("/")} color="inherit">
              HOME
            </Button>
            <Button onClick={() => history.push("/events")} color="inherit">
              MY EVENTS
            </Button>
            <Button onClick={() => history.push("/orgs")} color="inherit">
              ORGANIZATIONS
            </Button>
            {!token && (
              <Button onClick={() => history.push("/signin")} color="inherit">
                SIGN IN
              </Button>
            )}
            {token && (
              <Button
                onClick={() => {
                  dispatch(signOut())
                  history.push("/signin")
                }}
                color="inherit"
              >
                SIGN OUT
              </Button>
            )}
          </div>
        )}
        {width < 640 && <div style={{ width: 50 }}></div>}
      </Toolbar>
    </AppBar>
  )
}
export default Navbar
